<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12">
          <v-tooltip v-if="rs_id" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                plain
                icon
                :to="{
                  name: 'servicios.cartas',
                  params: { id: rs_id },
                }"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span v-text="'Átras'" />
          </v-tooltip>
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row v-if="!loading">
        <v-col cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span
                  class="text-caption"
                  v-text="`SERVICIO | ${rs_service.folio}`"
                />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items />
            </v-toolbar>
            <v-spacer />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Provedor"
                    :value="rs_service.provider.trade_name"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Asegurado"
                    :value="
                      rs_service.rs.enrollment + ' | ' + rs_service.rs.full_name
                    "
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Fecha"
                    :value="rs_service.letter_generated"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Monto asegurado"
                    :value="numberFormat(rs_service.assured_amount)"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card tile :loading="step_loading" :disabled="step_loading">
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'DOCUMENTACIÓN'" />
              </v-toolbar-title>
            </v-toolbar>
            <v-spacer />
            <v-card-text class="px-0 pb-0 pt-1">
              <v-alert
                v-if="data.observation"
                border="bottom"
                type="error"
                dense
              >
                {{ data.observation }}
              </v-alert>
              <v-stepper v-model="step" vertical flat>
                <v-stepper-step :complete="step > 1" step="1">
                  Archivos
                </v-stepper-step>
                <v-stepper-content step="1">
                  <v-form v-on:submit.prevent ref="form_data" lazy-validation>
                    <v-row dense>
                      <v-col v-if="login.role_id == 1" cols="12">
                        <v-checkbox
                          label="Incluir facturas"
                          v-model="data.with_bills"
                          color="success"
                          dense
                          class="mt-1 mr-1"
                        />
                      </v-col>
                      <v-col v-if="data.with_bills" cols="12">
                        <v-row
                          v-for="(document, i) in data.documents"
                          :key="i"
                          dense
                          v-if="document.active"
                        >
                          <v-col cols="12" class="grey darken-3 white--text">
                            <v-row dense>
                              <v-col cols="10">
                                <h4
                                  v-text="'FACTURA (' + (i + 1) + ')'"
                                  class="py-1 px-1"
                                />
                              </v-col>
                              <v-col cols="2" class="text-right mt-1">
                                <v-tooltip v-if="document.edit == false" left>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      class="mr-1"
                                      text
                                      icon
                                      x-small
                                      color="warning"
                                      @click.prevent="document.edit = true"
                                    >
                                      <v-icon> mdi-pencil </v-icon>
                                    </v-btn>
                                  </template>
                                  <span v-text="'Editar'" />
                                </v-tooltip>
                                <v-tooltip
                                  v-if="i > 0 && document.edit == null"
                                  left
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      class="mr-1"
                                      text
                                      icon
                                      x-small
                                      color="error"
                                      @click.prevent="documentRemove(i)"
                                    >
                                      <v-icon> mdi-close </v-icon>
                                    </v-btn>
                                  </template>
                                  <span v-text="'Eliminar'" />
                                </v-tooltip>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12" xs="12" md="4">
                            <v-file-input
                              v-if="document.edit || document.edit == null"
                              v-model="document.file_pdf"
                              label="PDF"
                              accept=".pdf"
                              show-size
                              :rules="rules.fileRequired800kB"
                            />
                            <DownloadFile
                              v-else
                              lab="PDF"
                              :b64="document.bill_b64"
                              ext="pdf"
                            />
                          </v-col>
                          <v-col cols="12" xs="12" md="4">
                            <v-file-input
                              v-if="document.edit || document.edit == null"
                              v-model="document.file_xml"
                              label="XML"
                              accept=".xml"
                              show-size
                              :rules="rules.fileRequired800kB"
                            />
                            <DownloadFile
                              v-else
                              lab="XML"
                              :b64="document.bill_xml_b64"
                              ext="xml"
                            />
                          </v-col>
                          <v-col cols="12" xs="12" md="4">
                            <v-text-field
                              v-if="document.edit || document.edit == null"
                              label="Monto total con IVA"
                              v-model="document.amount_typed"
                              type="number"
                              prepend-icon="mdi-currency-usd"
                              :rules="rules.required"
                            />
                            <ViewData
                              v-else
                              label="Monto total con IVA"
                              :value="numberFormat(document.amount_typed)"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            v-for="(note, j) in document.notes"
                            :key="j"
                            v-if="note.active"
                          >
                            <v-row dense>
                              <v-col cols="12" class="pb-4">
                                <v-divider />
                              </v-col>
                              <v-col cols="12" sm="12" md="3">
                                <v-file-input
                                  v-if="note.edit || note.edit == null"
                                  v-model="note.file_pdf"
                                  :label="`N. crédito ${i + 1}.${j + 1} (PDF)`"
                                  accept=".pdf"
                                  show-size
                                  dense
                                  :rules="rules.fileRequired800kB"
                                />
                                <DownloadFile
                                  v-else
                                  :lab="`N. crédito ${i + 1}.${j + 1} (PDF)`"
                                  :b64="note.b64"
                                  ext="pdf"
                                />
                              </v-col>
                              <v-col cols="12" sm="12" md="3">
                                <v-file-input
                                  v-if="note.edit || note.edit == null"
                                  v-model="note.file_xml"
                                  label="N. crédito (XML)"
                                  accept=".xml"
                                  show-size
                                  dense
                                  :rules="rules.fileRequired800kB"
                                />
                                <DownloadFile
                                  v-else
                                  lab="N. crédito (XML)"
                                  :b64="note.xml_b64"
                                  ext="xml"
                                />
                              </v-col>
                              <v-col cols="12" sm="12" md="3">
                                <v-text-field
                                  v-if="note.edit || note.edit == null"
                                  label="Monto escrito con IVA"
                                  v-model="note.amount_typed"
                                  type="number"
                                  dense
                                  prepend-icon="mdi-currency-usd"
                                  :rules="rules.required"
                                />
                                <ViewData
                                  v-else
                                  label="N. crédito (XML)"
                                  :value="note.amount_typed"
                                />
                              </v-col>
                              <v-col
                                cols="12"
                                xs="12"
                                md="3"
                                align-self="center"
                              >
                                <v-tooltip v-if="note.edit == false" left>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      class="mr-1"
                                      text
                                      icon
                                      x-small
                                      color="warning"
                                      @click.prevent="note.edit = true"
                                    >
                                      <v-icon medium> mdi-pencil </v-icon>
                                    </v-btn>
                                  </template>
                                  <span
                                    v-text="
                                      `Editar n. de crédito (${0 + 1}.${j + 1})`
                                    "
                                  />
                                </v-tooltip>
                                <v-tooltip v-if="note.edit == null" left>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      class="mr-1"
                                      text
                                      icon
                                      x-small
                                      color="error"
                                      @click.prevent="noteRemove(i, j)"
                                    >
                                      <v-icon medium> mdi-close </v-icon>
                                    </v-btn>
                                  </template>
                                  <span
                                    v-text="
                                      `Eliminar n. de crédito (${0 + 1}.${
                                        j + 1
                                      })`
                                    "
                                  />
                                </v-tooltip>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12">
                            <v-btn
                              v-if="document.notes.length == 0"
                              x-small
                              color=""
                              @click.prevent="noteAdd(i)"
                            >
                              <v-icon left> mdi-file-plus </v-icon>
                              Agregar n. de crédito
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="12">
                            <v-btn
                              v-if="data.documents.length < 2"
                              small
                              color="success"
                              @click.prevent="documentAdd"
                            >
                              <v-icon left> mdi-file-plus </v-icon>
                              Agregar factura
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col
                            cols="12"
                            v-for="(
                              rs_service_bill_file, i
                            ) in data.rs_service_bill_files"
                            :key="i"
                          >
                            <v-row dense v-if="rs_service_bill_file.active">
                              <v-col cols="12">
                                <v-divider class="pb-1" />
                              </v-col>
                              <v-col cols="12" sm="12" md="3">
                                <v-file-input
                                  v-if="
                                    rs_service_bill_file.edit ||
                                    rs_service_bill_file.edit == null
                                  "
                                  :label="`Archivo digital ${i + 1} (pdf)`"
                                  v-model="rs_service_bill_file.file"
                                  :rules="rules.fileRequired800kB"
                                  accept=".pdf"
                                  show-size
                                  dense
                                />
                                <DownloadFile
                                  v-else
                                  :lab="`Archivo digital ${i + 1} (pdf)`"
                                  :b64="rs_service_bill_file.b64"
                                  :ext="rs_service_bill_file.ext"
                                />
                              </v-col>
                              <v-col cols="12" sm="12" md="3">
                                <v-text-field
                                  v-if="
                                    rs_service_bill_file.edit ||
                                    rs_service_bill_file.edit == null
                                  "
                                  label="Descripción"
                                  v-model="rs_service_bill_file.description"
                                  maxlength="100"
                                  :rules="rules.required"
                                  type="text"
                                  counter
                                  prepend-icon="mdi-format-text"
                                  dense
                                />
                                <ViewData
                                  v-else
                                  :label="`Archivo digital ${i + 1} (pdf)`"
                                  :value="rs_service_bill_file.description"
                                />
                              </v-col>
                              <v-col cols="12" sm="12" md="3">
                                <v-tooltip
                                  v-if="rs_service_bill_file.edit == false"
                                  left
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      class="mr-1"
                                      text
                                      icon
                                      x-small
                                      color="warning"
                                      @click.prevent="
                                        rs_service_bill_file.edit = true
                                      "
                                    >
                                      <v-icon medium> mdi-pencil </v-icon>
                                    </v-btn>
                                  </template>
                                  <span
                                    v-text="`Editar archivo digital (${i + 1})`"
                                  />
                                </v-tooltip>
                                <v-tooltip
                                  v-if="rs_service_bill_file.edit == null"
                                  left
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      class="mr-1"
                                      text
                                      icon
                                      x-small
                                      color="error"
                                      @click.prevent="fileRemove(i)"
                                    >
                                      <v-icon medium> mdi-close </v-icon>
                                    </v-btn>
                                  </template>
                                  <span
                                    v-text="
                                      `Eliminar archivo digital (${i + 1})`
                                    "
                                  />
                                </v-tooltip>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12">
                            <v-btn x-small color="warning" @click="fileAdd">
                              <v-icon left> mdi-file-plus </v-icon>
                              Agregar archivo digital
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-row dense>
                    <v-col cols="12" sm="12" md="6" />
                    <v-col cols="12" sm="12" md="6">
                      <v-btn
                        small
                        block
                        color="primary"
                        @click.prevent="docVerify"
                      >
                        Continuar
                        <v-icon right> mdi-chevron-right </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-stepper-content>
                <v-stepper-step :complete="step > 2" step="2">
                  Verificación
                </v-stepper-step>
                <v-stepper-content step="2">
                  <v-row dense v-if="step == 2">
                    <v-col cols="12">
                      <v-alert v-if="data.bills_repeat" type="error" dense>
                        <div
                          class="text-center"
                          v-text="
                            'Una o varias facturas XML cargadas estan repetidas'
                          "
                        />
                      </v-alert>
                      <v-alert v-if="data.notes_repeat" type="error" dense>
                        <div
                          class="text-center"
                          v-text="
                            'Una o varias Notas de crédito XML cargadas estan repetidas'
                          "
                        />
                      </v-alert>
                    </v-col>
                    <!-- DOCUMENTS START -->
                    <v-col
                      v-if="data.with_bills"
                      cols="12"
                      v-for="(document, i) in data.documents"
                      :key="i"
                    >
                      <v-row dense>
                        <v-col cols="12" class="grey darken-1 white--text">
                          <h3 v-text="`Factura (${i + 1})`" class="pl-1" />
                        </v-col>

                        <v-col cols="12">
                          <v-alert
                            v-if="document.xml && document.bill_exist"
                            type="error"
                            dense
                          >
                            <div
                              class="text-center"
                              v-text="
                                `El folio de esta factura ya ha sido registrado para el servicio ${document.bill_exist_rs_folio}`
                              "
                            />
                          </v-alert>
                          <v-alert v-if="!document.xml" type="error" dense>
                            <div
                              class="text-center"
                              v-text="
                                'La factura XML no cuenta con un formato valido de lectura SAT'
                              "
                            />
                          </v-alert>
                          <v-alert
                            v-if="document.xml && !document.amount_typed_valid"
                            type="error"
                            dense
                          >
                            <div
                              class="text-center"
                              v-text="
                                'El monto total con IVA no es igual al Total de la factura'
                              "
                            />
                          </v-alert>
                          <v-alert
                            v-if="
                              document.xml && !document.valid_receiver_code_tax
                            "
                            type="error"
                            dense
                          >
                            <div
                              class="text-center"
                              v-text="
                                'El RFC del receptor no concuerda con el de Sinergia Médica'
                              "
                            />
                          </v-alert>
                          <v-alert
                            v-if="document.xml && !document.valid_receiver_name"
                            type="warning"
                            dense
                          >
                            <div
                              class="text-center"
                              v-text="
                                'La razón social del receptor no concuerda con el de Sinergia Médica'
                              "
                            />
                          </v-alert>
                          <v-alert
                            v-if="
                              document.xml &&
                              !document.valid_transmitter_code_tax
                            "
                            type="error"
                            dense
                          >
                            <div
                              class="text-center"
                              v-text="
                                'El RFC del emisor no concuerda con los datos registrados en nuestra base de datos'
                              "
                            />
                          </v-alert>
                          <!-- <v-alert
                            v-if="
                              document.xml && !document.valid_transmitter_name
                            "
                            type="error"
                            dense
                          >
                            <div
                              class="text-center"
                              v-text="
                                'La razón social del emisor no concuerda con los datos registrados en nuestra base de datos'
                              "
                            />
                          </v-alert> -->
                        </v-col>
                        <v-col cols="12" v-if="document.xml">
                          <v-row dense>
                            <v-col cols="12" sm="12" md="3">
                              <ViewData
                                label="Receptor"
                                :value="document.xml.Receptor.attr.Nombre"
                                :class="
                                  document.valid_receiver_name
                                    ? ''
                                    : 'orange--text text--darken-3'
                                "
                              />
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <ViewData
                                label="RFC"
                                :value="document.xml.Receptor.attr.Rfc"
                                :class="
                                  document.valid_receiver_code_tax
                                    ? ''
                                    : 'red--text text--darken-3'
                                "
                              />
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <ViewData
                                label="Fecha y hora de expedición"
                                :value="document.xml_stamped_date"
                              />
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <ViewData
                                :class="
                                  !document.amount_typed_valid
                                    ? 'red--text text--darken-3'
                                    : ''
                                "
                                label="Monto total con IVA"
                                :value="numberFormat(document.amount_typed)"
                              />
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <ViewData
                                label="Emisor"
                                :value="document.xml.Emisor.attr.Nombre"
                                :class="
                                  document.valid_transmitter_name
                                    ? ''
                                    : 'red--text text--darken-3'
                                "
                              />
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <ViewData
                                label="RFC"
                                :value="document.xml.Emisor.attr.Rfc"
                                :class="
                                  document.valid_transmitter_code_tax
                                    ? ''
                                    : 'red--text text--darken-3'
                                "
                              />
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <ViewData
                                label="Folio"
                                :value="document.xml_folio"
                              />
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <ViewData
                                label="Régimen fiscal"
                                :value="document.xml.Emisor.attr.RegimenFiscal"
                                :class="
                                  document.valid_regimen
                                    ? ''
                                    : 'red--text text--darken-3'
                                "
                              />
                            </v-col>
                            <v-col cols="12">
                              <span
                                class="text-caption font-weight-bold"
                                v-text="'Conceptos'"
                              />
                              <small>
                                <v-simple-table dense>
                                  <template v-slot:default>
                                    <thead>
                                      <tr>
                                        <th v-text="'#'" />
                                        <th v-text="'Unidad'" />
                                        <th v-text="'Cantidad'" />
                                        <th v-text="'Clave'" />
                                        <th v-text="'Descripción'" />
                                        <th v-text="'Precio'" />
                                        <th v-text="'Importe'" />
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        v-for="(concept, j) in document.xml
                                          .Conceptos.Concepto"
                                        :key="j"
                                      >
                                        <td
                                          class="text-caption font-weight-bold"
                                          v-text="j + 1"
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="concept.attr.ClaveUnidad"
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="concept.attr.Cantidad"
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="concept.attr.ClaveProdServ"
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="concept.attr.Descripcion"
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="
                                            numberFormat(
                                              concept.attr.ValorUnitario
                                            )
                                          "
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="
                                            numberFormat(concept.attr.Importe)
                                          "
                                        />
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td
                                          class="text-caption font-weight-bold"
                                          v-text="'Subtotal'"
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="
                                            numberFormat(
                                              document.xml.attr.SubTotal
                                            )
                                          "
                                        />
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td
                                          class="text-caption font-weight-bold"
                                          v-text="'Descuento'"
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="
                                            numberFormat(
                                              document.xml.attr.Descuento
                                                ? document.xml.attr.Descuento
                                                : 0
                                            )
                                          "
                                        />
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td
                                          class="text-caption font-weight-bold"
                                          v-text="'IVA'"
                                        />
                                        <td
                                          class="text-caption"
                                          v-text="
                                            document.xml.Impuestos &&
                                            document.xml.Impuestos.attr
                                              .TotalImpuestosTrasladados
                                              ? numberFormat(
                                                  document.xml.Impuestos.attr
                                                    .TotalImpuestosTrasladados
                                                )
                                              : numberFormat(0)
                                          "
                                        />
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td
                                          class="text-caption font-weight-bold"
                                          v-text="'Total'"
                                        />
                                        <td
                                          class="text-caption font-weight-bold"
                                          v-text="
                                            numberFormat(document.xml_amount)
                                          "
                                        />
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                              </small>
                            </v-col>
                          </v-row>
                        </v-col>
                        <!-- NOTES START -->
                        <v-col
                          cols="12"
                          v-for="(note, k) in document.notes"
                          :key="k"
                        >
                          <v-row dense>
                            <v-col cols="12" class="grey darken-1 white--text">
                              <h3
                                v-text="`N. crédito (${i + 1}.${k + 1})`"
                                class="pl-1"
                              />
                            </v-col>
                            <v-col cols="12">
                              <!-- <v-alert v-if="!note.xml" type="info" dense>
                                <div
                                  class="text-center"
                                  v-text="
                                    `No se cargo un archivo XML para la lectura del monto con IVA, se tomara en cuenta el monto escrito con IVA de ${numberFormat(
                                      note.amount_typed
                                    )}`
                                  "
                                />
                              </v-alert> -->
                              <v-alert
                                v-if="note.xml && note.note_exist"
                                type="error"
                                dense
                              >
                                <div
                                  class="text-center"
                                  v-text="
                                    `El folio hospital de esta factura ya ha sido registrado para el ingreso ${note.note_exist_rha_folio}`
                                  "
                                />
                              </v-alert>
                              <v-alert
                                v-if="note.xml && !note.receiver_code_tax"
                                type="warning"
                                dense
                              >
                                <div
                                  class="text-center"
                                  v-text="
                                    'El RFC del receptor no concuerda con el de Sinergia Médica'
                                  "
                                />
                              </v-alert>
                              <v-alert
                                v-if="note.xml && !note.receiver_name"
                                type="warning"
                                dense
                              >
                                <div
                                  class="text-center"
                                  v-text="
                                    'La razón social del receptor no concuerda con el de Sinergia Médica'
                                  "
                                />
                              </v-alert>
                              <v-alert
                                v-if="note.xml && !note.amount_typed_valid"
                                type="warning"
                                dense
                              >
                                <div
                                  class="text-center"
                                  v-text="
                                    'El monto escrito con IVA no es igual al del XML'
                                  "
                                />
                              </v-alert>
                            </v-col>
                            <v-col cols="12" v-if="note.xml">
                              <v-row dense>
                                <v-col cols="12" sm="12" md="3">
                                  <ViewData
                                    :class="
                                      note.receiver_name
                                        ? ''
                                        : 'orange--text text--darken-3'
                                    "
                                    label="Receptor"
                                    :value="note.xml.Receptor.attr.Nombre"
                                  />
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                  <ViewData
                                    :class="
                                      note.receiver_code_tax
                                        ? ''
                                        : 'orange--text text--darken-3'
                                    "
                                    label="RFC"
                                    :value="note.xml.Receptor.attr.Rfc"
                                  />
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                  <ViewData
                                    label="Fecha y hora de expedición"
                                    :value="note.xml.attr.Fecha"
                                  />
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                  <ViewData
                                    :class="
                                      !note.amount_typed_valid
                                        ? 'orange--text text--darken-3'
                                        : ''
                                    "
                                    label="Monto escrito con IVA"
                                    :value="numberFormat(note.amount_typed)"
                                  />
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                  <ViewData
                                    label="Emisor"
                                    :value="note.xml.Emisor.attr.Nombre"
                                  />
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                  <ViewData
                                    label="RFC"
                                    :value="note.xml.Emisor.attr.Rfc"
                                  />
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                  <ViewData
                                    label="Folio"
                                    :value="note.xml.attr.Folio"
                                  />
                                </v-col>
                                <v-col cols="12">
                                  <span
                                    class="text-caption font-weight-bold"
                                    v-text="`Conceptos`"
                                  />
                                  <small>
                                    <v-simple-table dense>
                                      <template v-slot:default>
                                        <thead>
                                          <tr>
                                            <th v-text="'#'" />
                                            <th v-text="'Unidad'" />
                                            <th v-text="'Cantidad'" />
                                            <th v-text="'Clave'" />
                                            <th v-text="'Descripción'" />
                                            <th v-text="'Precio'" />
                                            <th v-text="'Importe'" />
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr
                                            v-for="(concept, l) in note.xml
                                              .Conceptos.Concepto"
                                            :key="l"
                                          >
                                            <td
                                              class="text-caption font-weight-bold"
                                              v-text="l + 1"
                                            />
                                            <td
                                              class="text-caption"
                                              v-text="concept.attr.ClaveUnidad"
                                            />
                                            <td
                                              class="text-caption"
                                              v-text="concept.attr.Cantidad"
                                            />
                                            <td
                                              class="text-caption"
                                              v-text="
                                                concept.attr.ClaveProdServ
                                              "
                                            />
                                            <td
                                              class="text-caption"
                                              v-text="concept.attr.Descripcion"
                                            />
                                            <td
                                              class="text-caption"
                                              v-text="
                                                numberFormat(
                                                  concept.attr.ValorUnitario
                                                )
                                              "
                                            />
                                            <td
                                              class="text-caption"
                                              v-text="
                                                numberFormat(
                                                  concept.attr.Importe
                                                )
                                              "
                                            />
                                          </tr>
                                          <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td
                                              class="text-caption font-weight-bold"
                                              v-text="'Subtotal'"
                                            />
                                            <td
                                              v-text="
                                                numberFormat(
                                                  note.xml.attr.SubTotal
                                                )
                                              "
                                              class="text-caption"
                                            />
                                          </tr>
                                          <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td
                                              class="text-caption font-weight-bold"
                                              v-text="'Descuento'"
                                            />
                                            <td
                                              v-text="
                                                numberFormat(
                                                  note.xml.attr.Descuento
                                                    ? note.xml.attr.Descuento
                                                    : 0
                                                )
                                              "
                                              class="text-caption"
                                            />
                                          </tr>
                                          <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td
                                              class="text-caption font-weight-bold"
                                              v-text="'IVA'"
                                            />
                                            <td
                                              class="text-caption"
                                              v-text="
                                                numberFormat(
                                                  note.xml.Impuestos.attr
                                                    .TotalImpuestosTrasladados
                                                )
                                              "
                                            />
                                          </tr>
                                          <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td
                                              class="text-caption font-weight-bold"
                                              v-text="'Total'"
                                            />
                                            <td
                                              class="text-caption font-weight-bold"
                                              v-text="
                                                numberFormat(
                                                  note.xml.attr.Total
                                                )
                                              "
                                            />
                                          </tr>
                                        </tbody>
                                      </template>
                                    </v-simple-table>
                                  </small>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                        <!-- NOTES END -->
                      </v-row>
                    </v-col>
                    <!-- DOCUMENTS END -->
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-btn block @click.prevent="docFormData">
                        <v-icon left> mdi-chevron-left </v-icon>
                        Atrás
                      </v-btn>
                    </v-col>
                    <v-col cols="6">
                      <v-btn
                        block
                        :color="data.to_step_3 ? 'primary' : ''"
                        @click.prevent="docBeforeSubmit"
                      >
                        Continuar
                        <v-icon right> mdi-chevron-right </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-stepper-content>
                <v-stepper-step :complete="step > 3" step="3">
                  Envío
                </v-stepper-step>
                <v-stepper-content step="3">
                  <v-row v-if="step == 3">
                    <v-col cols="12" v-if="!data.to_stamp">
                      <v-alert type="warning" dense>
                        <div
                          class="text-center"
                          v-text="
                            'La documentación a cargar pasara a un proceso de revisión'
                          "
                        />
                        <div
                          v-if="!data.valid_assured_amount"
                          class="text-center"
                          v-text="
                            '*El monto de la factura supera el monto asegurado'
                          "
                        />
                        <!-- <div
                          v-if="!data.valid_receiver_names"
                          class="text-center"
                          v-text="
                            '*La razón social del receptor de una o varias facturas no es correcta'
                          "
                        /> -->
                      </v-alert>
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'Total factura'"
                      />
                      <span v-text="numberFormat(data.xmls_amount)" />
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'Total IVA'"
                      />
                      <span v-text="numberFormat(data.xmls_tax_amount)" />
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                      <div v-if="data.notes_amount != 0">
                        <span
                          class="text-caption font-weight-bold mr-1"
                          v-text="'Total Nota de Crédito'"
                        />
                        <span v-text="numberFormat(data.notes_amount)" />
                      </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'Total a pagar'"
                      />
                      <span v-text="numberFormat(data.pay_amount)" />
                    </v-col>
                    <v-col
                      v-if="data.with_bills"
                      cols="12"
                      v-for="(document, i) in data.documents"
                      :key="i"
                    >
                      <v-row dense>
                        <v-col cols="12" class="grey darken-3 white--text">
                          <h4
                            v-text="
                              `Factura (${i + 1}) | ${document.xml_folio}`
                            "
                            class="pl-1"
                          />
                        </v-col>
                        <v-col cols="12" sm="12" md="3">
                          <span
                            class="text-caption font-weight-bold mr-1"
                            v-text="'Factura PDF'"
                          />
                          <v-icon
                            right
                            small
                            :color="
                              (store_mode && data['document_bill_' + i]) ||
                              (!store_mode && document.url_bill)
                                ? 'success'
                                : 'warning'
                            "
                          >
                            {{
                              (store_mode && data["document_bill_" + i]) ||
                              (!store_mode && document.url_bill)
                                ? "mdi-check"
                                : "mdi-alert"
                            }}
                          </v-icon>
                        </v-col>
                        <v-col cols="12" sm="12" md="3">
                          <span
                            class="text-caption font-weight-bold mr-1"
                            v-text="'Factura XML'"
                          />
                          <v-icon
                            right
                            small
                            :color="document.xml_valid ? 'success' : 'warning'"
                          >
                            {{ document.xml_valid ? "mdi-check" : "mdi-alert" }}
                          </v-icon>
                        </v-col>
                        <v-col cols="12" sm="12" md="3">
                          <span
                            class="text-caption font-weight-bold mr-1"
                            v-text="'RFC y razon social'"
                          />
                          <v-icon
                            right
                            small
                            :color="
                              document.valid_receiver_code_tax &&
                              document.valid_receiver_name
                                ? 'success'
                                : 'warning'
                            "
                          >
                            {{
                              document.valid_receiver_code_tax &&
                              document.valid_receiver_name
                                ? "mdi-check"
                                : "mdi-alert"
                            }}
                          </v-icon>
                        </v-col>
                        <v-col cols="12" sm="12" md="3">
                          <span
                            class="text-caption font-weight-bold mr-1"
                            v-text="'Monto XML'"
                          />
                          <span v-text="numberFormat(document.xml_amount)" />
                        </v-col>
                        <v-col
                          cols="12"
                          v-for="(note, j) in document.notes"
                          :key="j"
                        >
                          <v-row dense>
                            <v-col cols="12">
                              <v-divider />
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <span
                                class="text-caption font-weight-bold mr-1"
                                v-text="
                                  `N. crédito PDF(${i + 1}.${j + 1}) ${
                                    note.folio ? `| ${note.folio}` : ''
                                  }`
                                "
                              />
                              <v-icon
                                right
                                small
                                :color="
                                  (store_mode &&
                                    data['document_note_pdf_' + j + '_' + i]) ||
                                  (!store_mode && note.url)
                                    ? 'success'
                                    : 'warning'
                                "
                              >
                                {{
                                  (store_mode &&
                                    data["document_note_pdf_" + j + "_" + i]) ||
                                  (!store_mode && note.url)
                                    ? "mdi-check"
                                    : "mdi-alert"
                                }}
                              </v-icon>
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <span
                                class="text-caption font-weight-bold mr-1"
                                v-text="'N. crédito XML'"
                              />
                              <v-icon
                                right
                                small
                                :color="note.xml_valid ? 'success' : 'default'"
                              >
                                {{ note.xml_valid ? "mdi-check" : "mdi-alert" }}
                              </v-icon>
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                              <span
                                class="text-caption font-weight-bold mr-1"
                                v-text="'Monto XML'"
                              />
                              <span
                                v-if="note.xml_amount"
                                v-text="numberFormat(note.xml_amount)"
                              />
                              <v-icon v-else right small color="default">
                                mdi-alert
                              </v-icon>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="6">
                      <v-btn block @click.prevent="step = 2">
                        <v-icon left> mdi-chevron-left </v-icon>
                        Atrás
                      </v-btn>
                    </v-col>
                    <v-col cols="6">
                      <v-btn block color="success" @click.prevent="docSubmit">
                        Cargar
                        <v-icon right> mdi-upload </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-stepper-content>
              </v-stepper>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  rules,
  msgAlert,
  msgConfirm,
  toFormData,
} from "../../control";
import ViewData from "../../components/ViewData.vue";
import DownloadFile from "../../components/DownloadFile.vue";

export default {
  components: {
    ViewData,
    DownloadFile,
  },
  data() {
    return {
      rs_service_id: parseInt(this.$route.params.rs_service_id),
      rs_id: null,
      login: this.$store.getters.getLogin,
      loading: true,
      rs_service: null,
      store_mode: true,
      data: null,
      step_loading: false,
      step: 1,
      rules: rules(),
      update: false,
    };
  },
  methods: {
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    fileAdd() {
      this.data.rs_service_bill_files.push({
        id: null,
        description: "",
        active: 1,
        document_id: null,
        file: null,
        edit: null,
      });
    },
    fileRemove(i) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar el archivo digital (${i + 1})?`))
        .then((resp) => {
          if (resp.isConfirmed) {
            if (this.data.rs_service_bill_files[i].id === null) {
              this.data.rs_service_bill_files.splice(i, 1);
            } else {
              this.data.rs_service_bill_files[i].active = false;
            }
          }
        });
    },
    init() {
      Axios.get(
        URL_API + "/rs_service/" + this.rs_service_id + "/documentation",
        headersToken(this.login.token)
      ).then((rsp) => {
        this.rs_service = rsp.data.data;
        this.rs_id = this.rs_service.rs_id;

        let rs_service_bill = this.rs_service.rs_service_bill;
        this.store_mode = rs_service_bill == null;

        if (this.store_mode) {
          this.data = {
            id: null,
            active: true,
            provider_id: this.rs_service.provider_id,
            rs_service_id: this.rs_service.id,
            store_mode: true,
            with_bills: true,
            //-------------
            pay_amount: null,
            insurance_pay_amount: null,
            taxed: null,
            xmls_valid: null,
            xmls_subtotal_amount: null,
            xmls_discount_amount: null,
            xmls_tax_amount: null,
            xmls_amount: null,
            notes_amount: null,
            notes_subtotal_amount: null,
            valid_receiver_code_taxs: null,
            valid_receiver_names: null,
            valid_amounts: null,
            to_stamp: null,
            bills_repeat: null,
            bills_exist: null,
            notes_repeat: null,
            notes_exist: null,
            to_step_3: null,
            valid_transmitter_code_taxs: null,
            valid_transmitter_names: null,
            valid_bills: null,
            valid_regimens: null,
            amount_typed_valids: null,
            valid_assured_amount: null,
            valid_rs_service_insured_bill_pay_amount: null,
            bills_with_notes: null,
            deductibles_amount: null,
            coinsurances_amount: null,
            discounts_amount: null,
            //-------------
            documents: [
              {
                id: null,
                active: true,
                url_bill_xml: null,
                edit: null,
                //-------------
                amount_typed: "0",
                xml_folio: null,
                xml_stamped_date: null,
                xml_amount: null,
                valid_receiver_code_tax: null,
                valid_receiver_name: null,
                notes_amount: null,
                xml: null,
                bill_exist: null,
                bill_exist_rs_folio: null,
                xml_tax_amount: null,
                amount_typed_valid: null,
                xml_valid: null,
                valid_transmitter_code_tax: null,
                valid_transmitter_name: null,
                valid_bill: null,
                valid_regimen: null,
                valid_iva: null,
                valid_isr: null,
                importe_isr: null,
                importe_iva: null,
                xml_subtotal_amount: null,
                xml_iva_amount: null,
                xml_isr_amount: null,
                fiscal_regime_id: null,
                fiscal_regime: null,
                file_pdf: null,
                file_xml: null,
                //-------------
                notes: [],
              },
            ],
            rs_service_bill_files: [],
          };
        } else {
          this.data = rs_service_bill;
          this.data.provider_id = this.rs_service.provider_id;
          this.data.rs_service_id = this.rs_service.id;
        }

        this.loading = false;
      });
    },
    documentAdd() {
      this.data.documents.push({
        id: null,
        active: true,
        url_bill_xml: null,
        edit: null,
        //-------------
        amount_typed: "0",
        xml_folio: null,
        xml_stamped_date: null,
        xml_amount: null,
        valid_receiver_code_tax: null,
        valid_receiver_name: null,
        notes_amount: null,
        xml: null,
        bill_exist: null,
        bill_exist_rs_folio: null,
        xml_tax_amount: null,
        amount_typed_valid: null,
        xml_valid: null,
        valid_transmitter_code_tax: null,
        valid_transmitter_name: null,
        valid_bill: null,
        valid_regimen: null,
        valid_iva: null,
        valid_isr: null,
        importe_isr: null,
        importe_iva: null,
        xml_subtotal_amount: null,
        xml_iva_amount: null,
        xml_isr_amount: null,
        fiscal_regime_id: null,
        fiscal_regime: null,
        file_pdf: null,
        file_xml: null,
        //-------------
        notes: [],
      });
    },
    documentRemove(i) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar la factura (${i + 1})?`))
        .then((response) => {
          if (response.isConfirmed) {
            if (this.data.documents[i].id === null) {
              this.data.documents.splice(i, 1);
            } else {
              this.data.documents[i].active = false;
            }
          }
        });
    },
    noteAdd(i) {
      this.data.documents[i].notes.push({
        id: null,
        active: true,
        url_bill: null,
        url_xml: null,
        edit: null,
        //-----------------
        amount_typed: "0",
        xml_folio: null,
        xml_stamped_date: null,
        xml_amount: null,
        xml: null,
        receiver_code_tax: null,
        receiver_name: null,
        note_exist: null,
        note_exist_rs_xml_folio: null,
        amount_typed_valid: null,
        xml_valid: null,
        file_pdf: null,
        file_xml: null,
      });
    },
    noteRemove(i, j) {
      this.$swal
        .fire(
          msgConfirm(
            `¿Confirma eliminar la n. de crédito (${j + 1}) de la factura (${
              i + 1
            })?`
          )
        )
        .then((response) => {
          if (response.isConfirmed) {
            if (this.data.documents[i].notes[j].id === null) {
              this.data.documents[i].notes.splice(j, 1);
            } else {
              this.data.documents[i].notes[j].active = false;
            }
          }
        });
    },
    docVerify() {
      if (this.$refs.form_data.validate()) {
        this.step_loading = true;

        let obj = Object.assign({}, this.data);

        if (this.data.with_bills) {
          let i = 0;
          for (let document of obj.documents) {
            obj["document_bill_xml_" + i] = document.file_xml;

            let j = 0;
            for (let note of document.notes) {
              obj["document_note_xml_" + j + "_" + i] = note.file_xml;

              j++;
            }

            i++;
          }
        }

        Axios.post(
          `${URL_API}/rs_service/documentation_verify`,
          toFormData(obj),
          headersToken(this.login.token)
        ).then((resp) => {
          if (resp.data.success) {
            resp = resp.data.data;

            this.data.pay_amount = resp.pay_amount;
            this.data.insurance_pay_amount = resp.insurance_pay_amount;
            this.data.taxed = resp.taxed;
            this.data.xmls_valid = resp.xmls_valid;
            this.data.xmls_subtotal_amount = resp.xmls_subtotal_amount;
            this.data.xmls_discount_amount = resp.xmls_discount_amount;
            this.data.xmls_tax_amount = resp.xmls_tax_amount;
            this.data.xmls_amount = resp.xmls_amount;
            this.data.notes_amount = resp.notes_amount;
            this.data.notes_subtotal_amount = resp.notes_subtotal_amount;
            this.data.valid_receiver_code_taxs = resp.valid_receiver_code_taxs;
            this.data.valid_receiver_names = resp.valid_receiver_names;
            this.data.valid_amounts = resp.valid_amounts;
            this.data.to_stamp = resp.to_stamp;
            this.data.bills_repeat = resp.bills_repeat;
            this.data.bills_exist = resp.bills_exist;
            this.data.notes_repeat = resp.notes_repeat;
            this.data.notes_exist = resp.notes_exist;
            this.data.to_step_3 = resp.to_step_3;
            this.data.valid_transmitter_code_taxs =
              resp.valid_transmitter_code_taxs;
            this.data.valid_transmitter_names = resp.valid_transmitter_names;
            this.data.valid_bills = resp.valid_bills;
            this.data.valid_regimens = resp.valid_regimens;
            this.data.amount_typed_valids = resp.amount_typed_valids;
            this.data.valid_assured_amount = resp.valid_assured_amount;
            this.data.valid_rs_service_insured_bill_pay_amount =
              resp.valid_rs_service_insured_bill_pay_amount;
            this.data.bills_with_notes = resp.bills_with_notes;
            this.data.deductibles_amount = resp.deductibles_amount;
            this.data.coinsurances_amount = resp.coinsurances_amount;
            this.data.discounts_amount = resp.discounts_amount;

            if (this.data.with_bills) {
              for (let i = 0; i < this.data.documents.length; i++) {
                this.data.documents[i].amount_typed =
                  resp.documents[i].amount_typed;
                this.data.documents[i].xml_folio = resp.documents[i].xml_folio;
                this.data.documents[i].xml_stamped_date =
                  resp.documents[i].xml_stamped_date;
                this.data.documents[i].xml_amount =
                  resp.documents[i].xml_amount;
                this.data.documents[i].valid_receiver_code_tax =
                  resp.documents[i].valid_receiver_code_tax;
                this.data.documents[i].valid_receiver_name =
                  resp.documents[i].valid_receiver_name;
                this.data.documents[i].notes_amount =
                  resp.documents[i].notes_amount;
                this.data.documents[i].xml = resp.documents[i].xml;
                this.data.documents[i].bill_exist =
                  resp.documents[i].bill_exist;
                this.data.documents[i].bill_exist_rs_folio =
                  resp.documents[i].bill_exist_rs_folio;
                this.data.documents[i].xml_tax_amount =
                  resp.documents[i].xml_tax_amount;
                this.data.documents[i].amount_typed_valid =
                  resp.documents[i].amount_typed_valid;
                this.data.documents[i].xml_valid = resp.documents[i].xml_valid;
                this.data.documents[i].valid_transmitter_code_tax =
                  resp.documents[i].valid_transmitter_code_tax;
                this.data.documents[i].valid_transmitter_name =
                  resp.documents[i].valid_transmitter_name;
                this.data.documents[i].valid_bill =
                  resp.documents[i].valid_bill;
                this.data.documents[i].valid_regimen =
                  resp.documents[i].valid_regimen;
                this.data.documents[i].valid_iva = resp.documents[i].valid_iva;
                this.data.documents[i].valid_isr = resp.documents[i].valid_isr;
                this.data.documents[i].importe_isr =
                  resp.documents[i].importe_isr;
                this.data.documents[i].importe_iva =
                  resp.documents[i].importe_iva;
                this.data.documents[i].xml_subtotal_amount =
                  resp.documents[i].xml_subtotal_amount;
                this.data.documents[i].xml_iva_amount =
                  resp.documents[i].xml_iva_amount;
                this.data.documents[i].xml_isr_amount =
                  resp.documents[i].xml_isr_amount;
                this.data.documents[i].fiscal_regime_id =
                  resp.documents[i].fiscal_regime_id;
                this.data.documents[i].fiscal_regime =
                  resp.documents[i].fiscal_regime;

                for (let j = 0; j < this.data.documents[i].notes.length; j++) {
                  this.data.documents[i].notes[j].amount_typed =
                    resp.documents[i].notes[j].amount_typed;
                  this.data.documents[i].notes[j].xml_folio =
                    resp.documents[i].notes[j].xml_folio;
                  this.data.documents[i].notes[j].xml_stamped_date =
                    resp.documents[i].notes[j].xml_stamped_date;
                  this.data.documents[i].notes[j].xml_amount =
                    resp.documents[i].notes[j].xml_amount;
                  this.data.documents[i].notes[j].xml =
                    resp.documents[i].notes[j].xml;
                  this.data.documents[i].notes[j].receiver_code_tax =
                    resp.documents[i].notes[j].receiver_code_tax;
                  this.data.documents[i].notes[j].receiver_name =
                    resp.documents[i].notes[j].receiver_name;
                  this.data.documents[i].notes[j].note_exist =
                    resp.documents[i].notes[j].note_exist;
                  this.data.documents[i].notes[j].note_exist_rs_xml_folio =
                    resp.documents[i].notes[j].note_exist_rs_xml_folio;
                  this.data.documents[i].notes[j].amount_typed_valid =
                    resp.documents[i].notes[j].amount_typed_valid;
                  this.data.documents[i].notes[j].xml_valid =
                    resp.documents[i].notes[j].xml_valid;
                }
              }
            }

            this.step = 2;
          } else {
            this.$swal.fire(msgAlert("error", resp.data.message));
            console.log(resp.data.message);
          }

          this.step_loading = false;
        });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    docDefVals() {
      this.data.pay_amount = null;
      this.data.insurance_pay_amount = null;
      this.data.taxed = null;
      this.data.xmls_valid = null;
      this.data.xmls_subtotal_amount = null;
      this.data.xmls_discount_amount = null;
      this.data.xmls_tax_amount = null;
      this.data.xmls_amount = null;
      this.data.notes_amount = null;
      this.data.notes_subtotal_amount = null;
      this.data.valid_receiver_code_taxs = null;
      this.data.valid_receiver_names = null;
      this.data.valid_amounts = null;
      this.data.to_stamp = null;
      this.data.bills_repeat = null;
      this.data.bills_exist = null;
      this.data.notes_repeat = null;
      this.data.notes_exist = null;
      this.data.to_step_3 = null;
      this.data.valid_transmitter_code_taxs = null;
      this.data.valid_transmitter_names = null;
      this.data.valid_bills = null;
      this.data.valid_regimens = null;
      this.data.amount_typed_valids = null;
      this.data.valid_assured_amount = null;
      this.data.valid_rs_service_insured_bill_pay_amount = null;
      this.data.bills_with_notes = null;
      this.data.deductibles_amount = null;
      this.data.coinsurances_amount = null;
      this.data.discounts_amount = null;

      for (let i = 0; i < this.data.documents.length; i++) {
        this.data.documents[i].xml_folio = null;
        this.data.documents[i].xml_stamped_date = null;
        this.data.documents[i].xml_amount = null;
        this.data.documents[i].valid_receiver_code_tax = null;
        this.data.documents[i].valid_receiver_name = null;
        this.data.documents[i].notes_amount = null;
        this.data.documents[i].xml = null;
        this.data.documents[i].bill_exist = null;
        this.data.documents[i].bill_exist_rs_folio = null;
        this.data.documents[i].xml_tax_amount = null;
        this.data.documents[i].amount_typed_valid = null;
        this.data.documents[i].xml_valid = null;
        this.data.documents[i].valid_transmitter_code_tax = null;
        this.data.documents[i].valid_transmitter_name = null;
        this.data.documents[i].valid_bill = null;
        this.data.documents[i].valid_regimen = null;
        this.data.documents[i].valid_iva = null;
        this.data.documents[i].valid_isr = null;
        this.data.documents[i].importe_isr = null;
        this.data.documents[i].importe_iva = null;
        this.data.documents[i].xml_subtotal_amount = null;
        this.data.documents[i].xml_iva_amount = null;
        this.data.documents[i].xml_isr_amount = null;
        this.data.documents[i].fiscal_regime_id = null;
        this.data.documents[i].fiscal_regime = null;

        for (let j = 0; j < this.data.documents[i].notes.length; j++) {
          this.data.documents[i].notes[j].xml_folio = null;
          this.data.documents[i].notes[j].xml_stamped_date = null;
          this.data.documents[i].notes[j].xml_amount = null;
          this.data.documents[i].notes[j].xml = null;
          this.data.documents[i].notes[j].receiver_code_tax = null;
          this.data.documents[i].notes[j].receiver_name = null;
          this.data.documents[i].notes[j].note_exist = null;
          this.data.documents[i].notes[j].note_exist_rs_xml_folio = null;
          this.data.documents[i].notes[j].amount_typed_valid = null;
          this.data.documents[i].notes[j].xml_valid = null;
        }
      }
    },
    docFormData() {
      this.step_loading = true;
      this.docDefVals();
      this.step_loading = false;
      this.step = 1;
    },
    docBeforeSubmit() {
      if (this.data.to_step_3) {
        this.step = 3;
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Existen detalles remarcados en rojo que deben ser atendidos para continuar"
          )
        );
      }
    },
    docSubmit() {
      this.$swal
        .fire(msgConfirm(`¿Confirma cargar la documentación?`))
        .then((resp) => {
          if (resp.isConfirmed) {
            this.step_loading = true;

            let obj = Object.assign({}, this.data);

            if (this.data.with_bills) {
              let i = 0;
              for (let document of obj.documents) {
                obj["document_bill_" + i] = document.file_pdf;
                obj["document_bill_xml_" + i] = document.file_xml;

                let j = 0;
                for (let note of document.notes) {
                  obj["document_note_pdf_" + j + "_" + i] = note.file_pdf;
                  obj["document_note_xml_" + j + "_" + i] = note.file_xml;
                  j++;
                }
                i++;
              }
            } else {
              obj.documents = [];
              obj.documents.push({
                id: null,
                active: true,
                url_bill_xml: null,
                edit: null,
                //-------------
                amount_typed: "0",
                xml_folio: null,
                xml_stamped_date: null,
                xml_amount: null,
                valid_receiver_code_tax: null,
                valid_receiver_name: null,
                notes_amount: null,
                xml: null,
                bill_exist: null,
                bill_exist_rs_folio: null,
                xml_tax_amount: null,
                amount_typed_valid: null,
                xml_valid: null,
                valid_transmitter_code_tax: null,
                valid_transmitter_name: null,
                valid_bill: null,
                valid_regimen: null,
                valid_iva: null,
                valid_isr: null,
                importe_isr: null,
                importe_iva: null,
                xml_subtotal_amount: null,
                xml_iva_amount: null,
                xml_isr_amount: null,
                fiscal_regime_id: null,
                fiscal_regime: null,
                file_pdf: null,
                file_xml: null,
                //-------------
                notes: [],
              });
            }

            Axios.post(
              URL_API + "/rs_service/bill/save",
              toFormData(obj),
              headersToken(this.login.token)
            ).then((resp) => {
              this.$swal.fire(
                msgAlert(
                  resp.data.success ? "success" : "error",
                  resp.data.message
                )
              );

              // if (resp.data.success) {
              //   this.$router.push({
              //     name: "servicios.cartas",
              //     params: { id: rs_id },
              //   });
              // }

              this.step_loading = false;
            });
          }
        });
    },
  },
  mounted() {
    this.init();
  },
};
</script>